"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTimestamp = exports.pad0s = void 0;
function pad0s(time) {
    if (time < 10) {
        return "0" + time;
    }
    else {
        return time;
    }
}
exports.pad0s = pad0s;
function formatTimestamp(time) {
    if (time >= 60) {
        return Math.floor(time / 60) + ":" + pad0s(Math.floor(time % 60));
    }
    else if (!isNaN(time)) {
        return "0:" + pad0s(Math.floor(time % 60));
    }
    else {
        return "0:00";
    }
}
exports.formatTimestamp = formatTimestamp;
